import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  EyeFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Modal, notification, Switch, Table } from "antd";
import { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { BiDownload } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Buttons, InputSearch } from "../../components/atoms/index";
import {
  deleteAddOns,
  getListAddOns,
  getOneAddOns,
  importProductData,
} from "../../services/api";
import { numberWithCommas } from "../../utils/Helper";
import FormSection from "./FormSection/FormSection";

const { confirm } = Modal;

export default function AddOnsList() {
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  const [childData, setChildData] = useState({});
  const [totalData, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const roles = useSelector((state) => state.auth.user.role_slug);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    keyword: "",
  });

  const getData = () => {
    setLoading(true);
    getListAddOns(params)
      .then((res) => {
        const responseData = res?.data?.data?.result;
        console.log("Response Data:", responseData);
        if (Array.isArray(responseData)) {
          setData(responseData);
          setTotal(res.data.data.total);
        } else {
          setData([]);
          setTotal(0);
        }
      })
      .catch((err) => {
        notification.error({
          message: "Error!",
          description: "Failed to fetch data.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [trigger, params]);

  function onPageChange(page, pageSize) {
    setParams({ ...params, page, page_size: pageSize });
  }

  const onSearch = _.debounce((e) => {
    setParams((prevParams) => ({
      ...prevParams,
      keyword: e.target.value,
      offset: 0,
    }));
  }, 500);

  useEffect(() => {
    if (section === "default") {
      setChildData({});
      getData();
    }
  }, [section]);

  const editData = (record) => {
    getOneAddOns(record.id)
      .then((res) => {
        setChildData(res.data.data);
        setSection("edit");
      })
      .catch((err) => {
        notification.error({
          message: "Error!",
          description: "Failed to fetch add-on details.",
        });
      });
  };

  const viewData = (record) => {
    getOneAddOns(record.id)
      .then((res) => {
        setChildData(res.data.data);
        setSection("view");
      })
      .catch((err) => {
        notification.error({
          message: "Error!",
          description: "Failed to fetch add-on details.",
        });
      });
  };

  const addData = () => {
    setSection("add");
  };

  const deleteData = (record) => {
    console.log("Record:", record);
    if (!record.id) {
      notification.error({
        message: "Error!",
        description: "Record ID is missing.",
      });
      return;
    }
    confirm({
      title: "Confirm Delete",
      content:
        "Are you sure you want to delete this data? This action cannot be undone.",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteAddOns(record.id)
          .then(() => {
            notification.success({
              message: "Success!",
              description: "Successfully deleted data.",
            });
            getData();
          })
          .catch((err) => {
            notification.error({
              message: "Failed!",
              description: err?.data?.stat_msg || "Failed to delete data.",
            });
          });
      },
    });
  };

  const hiddenFileInput = useRef(null);

  // const handleClick = () => {
  //   hiddenFileInput.current.click();
  // };

  const handleChange = async (event) => {
    setLoadingImport(true);
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append("file", fileUploaded);

    try {
      await importProductData(formData);
      notification.success({
        message: "Success!",
        description: "Successfully imported office data.",
      });
      getData();
    } catch (err) {
      notification.error({
        message: "Failed!",
        description: "Failed to import office data.",
      });
    } finally {
      setLoadingImport(false);
    }
  };

  const handleDownloadData = async () => {
    const token = localStorage.getItem("token");
    setLoadingDownload(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/catering/report`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const blob = await response.blob();
      saveAs(blob, `REPORT_${moment().format("l")}.xlsx`);
    } catch (error) {
      notification.error({
        message: "Failed!",
        description: "Failed to download file.",
      });
    } finally {
      setLoadingDownload(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Price",
      render: (text, record) => {
        // Determine the price based on the category
        let price = 0;
        if (record.category === 10) {
          price = record.catering_price.price_per_event;
        } else if (record.category === 11) {
          price = record.catering_price.price_per_pax;
        }

        // Format the price with commas and return the appropriate display
        return (
          <span>
            Rp {numberWithCommas(price)} {record.catering_price?.pax_type}
          </span>
        );
      },
      sorter: (a, b) => {
        const priceA =
          a.category === 11
            ? a.catering_price.price_per_event
            : a.catering_price.price_per_pax;
        const priceB =
          b.category === 11
            ? b.catering_price.price_per_event
            : b.catering_price.price_per_pax;
        return priceA - priceB;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <Switch checked={record.status === true} disabled={true} />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div className="flex items-center gap-x-1">
          <Buttons
            className="py-1 rounded-lg hover:border-primary"
            icon={<EyeFilled className="text-gray-600 hover:text-primary" />}
            onClick={() => viewData(record)}
          />
          <Buttons
            className="py-1 rounded-lg hover:border-primary"
            icon={<EditFilled className="text-gray-600 hover:text-primary" />}
            onClick={() => editData(record)}
          />
          <Buttons
            className="py-1 rounded-lg hover:border-primary"
            icon={<DeleteFilled className="text-gray-600 hover:text-primary" />}
            onClick={() => deleteData(record)}
          />
        </div>
      ),
    },
  ];

  // const handleDownloadData = () => {
  //   try {
  //     setLoadingDownload(true);
  //     const response = downloadListProduct();
  //     const blob = response.blob();
  //     saveAs(blob, `REPORT_${moment().format("l")}.xlsx`);
  //     setLoadingDownload(false);
  //   } catch (error) {
  //     console.log("Error");
  //     setLoadingDownload(false);
  //   }
  // };
  // const handleDownloadTemplate = () => {
  //   downloadTemplateImport()
  //     .then((res) => {
  //       downloadFile(res.data.data);
  //     })
  //     .catch((err) => {
  //       return new Error(err);
  //     });
  // };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="breadcrumb mb-4 text-md">
            <Link to="/" className="text-gray-500">
              Dashboard
            </Link>
            <span className="mx-2">&gt;</span>
            <span>Add Ons List</span>
          </div>
          <div className="header">
            <h2 className="lg:text-[28px] text-[20px] font-bold">
              Add-Ons List
            </h2>
            <div className="flex flex-col md:flex-row items-center md:gap-4 gap-1">
              <button
                className={`flex items-center justify-center px-4 py-2 rounded-md bg-[#F9E7E7] text-primary hover:bg-[#F0D0D0] transition-colors ${
                  loadingDownload ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleDownloadData}
                disabled={loadingDownload}
              >
                {loadingDownload ? (
                  <span className="mr-2 animate-spin">&#9696;</span>
                ) : (
                  <BiDownload className="mr-2" />
                )}
                Download Data
              </button>
              {roles !== "super-admin-distributor" && (
                <Buttons
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => addData()}
                >
                  Add Data
                </Buttons>
              )}
            </div>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </div>
          <div className="mb-6 md:w-80 w-32">
            <InputSearch onChange={onSearch} placeholder="Search Add Ons" />
          </div>
          <div className="body">
            <Table
              dataSource={data}
              columns={columns}
              rowKey="id"
              loading={loading}
              pagination={{
                current: params.page,
                pageSize: params.page_size,
                total: totalData,
                onChange: onPageChange,
                showTotal: (total, range) => (
                  <div>
                    Showing <strong>{range[1]}</strong> of{" "}
                    <strong>{total}</strong> entries
                  </div>
                ),
                className: "ant-table-pagination-custom",
                itemRender: (_, type, originalElement) => {
                  if (type === "prev") {
                    return <Button type="text">&lt;</Button>;
                  }
                  if (type === "next") {
                    return <Button type="text">&gt;</Button>;
                  }
                  return originalElement;
                },
              }}
            />
          </div>
        </div>
      )}
      {section !== "default" && (
        <FormSection
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )}
    </>
  );
}
