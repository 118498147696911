import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  notification,
  Row,
  Select,
  Switch,
  Upload,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Buttons } from "../../../components/atoms/index";
import { postAddOns, putAddOns } from "../../../services/api";
import { formatRupiah } from "../../../utils/Helper";
import { getLabel } from "../../../utils/validateMessage";
import OfficeDetail from "./AddOnsDetail";

const { TextArea } = Input;
const { Option } = Select;

export default function FormSection(props) {
  const { setSection, section, childData } = props;
  const [form] = Form.useForm();
  const NUMBER_VALIDATOR = /^[0-9]*$/;

  // list ref
  const AddOnsInformationRef = useRef();
  const AddOnsImageRef = useRef();
  const AddOnsPriceRef = useRef();

  // handle file upload
  const [file, setFile] = useState();
  const [fileBase64, setFileBase64] = useState();
  const [modalPreview, setModalPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [category, setCategory] = useState(
    section === "edit" ? childData.category : null
  );

  const [pricePerPax, setPricePerPax] = useState(
    section === "edit" ? childData.catering_price.price_per_pax : 0
  );
  const [pricePerEvent, setPricePerEvent] = useState(
    section === "edit" ? childData.catering_price.price_per_event : 0
  );
  const [paxType, setPaxType] = useState(
    section === "edit" ? childData.catering_price.pax_type : ""
  );

  const handleCategoryChange = (value) => {
    setCategory(value);
    if (value === 10) {
      setPaxType("Per Event");
    } else if (value === 11) {
      setPaxType("Per Pax/Per Hour");
    } else {
      setPaxType("");
    }
  };

  useEffect(() => {
    if (section === "edit") {
      setFile(childData.url ? { url: childData.url } : null);
      if (childData.url) {
        setFileBase64(childData.url);
      }

      form.setFieldsValue({
        name: childData.name,
        description: childData.description,
        status: childData.status,
        category: childData.category,
        pax_type: childData.catering_price.pax_type,
        price_per_pax: childData.catering_price.price_per_pax,
        price_per_event: childData.catering_price.price_per_event,
      });

      if (childData.category === 10) {
        setPaxType("Per Event");
      } else if (childData.category === 11) {
        setPaxType("Per Pax/Per Hour");
      }
    } else {
      setPaxType("");
    }
  }, [section, childData, form]);

  // set input
  const [inputPricePerPax, setInputPricePerPax] = useState({
    status: "success",
    message: "",
    value:
      childData.catering_price &&
      childData.catering_price.price_per_pax !== undefined
        ? childData.catering_price.price_per_pax
        : "",
  });
  const [inputPricePerEvent, setInputPricePerEvent] = useState({
    status: "success",
    message: "",
    value:
      childData.catering_price &&
      childData.catering_price.price_per_event !== undefined
        ? childData.catering_price.price_per_event
        : "",
  });

  const onFinish = (values) => {
    if (!file) {
      notification.error({
        message: "Error",
        description: "You must provide a photo for product Add-On",
      });
      return;
    }

    const result = {
      name: values.name,
      description: values.description,
      category: values.category,
      pax_type: paxType,
      status: values.status,
      price_per_pax: pricePerPax,
      price_per_event: pricePerEvent,
      attachment: file.originFileObj || file,
    };

    console.log("values", values);
    console.log("result", result);

    setIsLoading(true);

    if (section === "edit") {
      // console.log("form edit", result);
      // console.log("childData", childData);
      putAddOns(childData, result)
        .then(() => {
          notification.success({
            message: "Success",
            description: "Successfully updated the add-on!",
          });
          setSection("default");
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: "Error",
            description: "Failed to update the add-on!",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      postAddOns(result)
        .then(() => {
          notification.success({
            message: "Success",
            description: "Successfully added the add-on!",
          });
          setSection("default");
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return false;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      setFileBase64(e.target.result);
    };
    reader.readAsDataURL(file);

    setFile(file);
    return false;
  };

  const handlePreview = () => {
    setModalPreview(true);
  };

  const normFile = (e) => {
    return e?.fileList;
  };

  const handleRemove = () => {
    setFile(null);
    setFileBase64(null);
  };

  const handleInputNumber = (event, setInputAmount, setPriceFunction) => {
    const realtimeValue = Number(event.target.value.replace(/\D/g, ""));
    setPriceFunction(realtimeValue);
    if (!NUMBER_VALIDATOR.test(realtimeValue)) {
      setInputAmount({
        status: "error",
        message: "Please only enter numbers!",
        value: realtimeValue,
      });
    } else {
      setInputAmount({
        status: "success",
        message: "",
        value: realtimeValue,
      });
    }
  };

  return (
    <div className="content-section">
      <div className="breadcrumb mb-4 text-md">
        <Link to="/" className="text-gray-500">
          Dashboard
        </Link>
        <span className="mx-2">&gt;</span>
        <Link
          to="#"
          onClick={() => setSection("default")}
          className="text-gray-500"
        >
          Add Ons List
        </Link>
        {section !== "default" && (
          <>
            <span className="mx-2">&gt;</span>
            <span>
              {section === "add"
                ? "Add Add Ons"
                : section === "view"
                ? "Detail"
                : "Edit"}
            </span>
          </>
        )}
      </div>
      <div className="header">
        <div className="flex items-center">
          <div className="text-3xl font-bold">
            {section === "add" && "Add Add-Ons"}
            {section === "view" && "Add-Ons Detail"}
            {section === "edit" && "Edit Office/Service"}
          </div>
        </div>
      </div>
      <div className="body max-body-height pt-3 pb-0">
        {section !== "view" ? (
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            validateMessages={getLabel}
          >
            <div className="relative scroll-custom">
              <div ref={AddOnsInformationRef} className="fit-scroll py-2">
                <Row justify="space-between" align="middle">
                  <div className="text-xl font-bold text-black mb-2">
                    Add-Ons information
                  </div>
                  <Form.Item
                    layout="horizontal"
                    label={getLabel("Active", false)}
                    name="status"
                    valuePropName="checked"
                    initialValue={true}
                    rules={[{ required: false }]}
                  >
                    <Switch />
                  </Form.Item>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      label={getLabel("Add-Ons Name", true)}
                      name="name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Enter Add-Ons name" />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      label={getLabel("Description", false)}
                      name="description"
                    >
                      <TextArea rows={4} placeholder="Enter description" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Divider style={{ borderWidth: "1px" }} />
              <div ref={AddOnsPriceRef} className="fit-scroll py-2">
                <div className="text-xl font-bold text-black mb-2">Price</div>
                <div className="text-md font-regular text-gray-500 mb-6">
                  Select one that apply.
                </div>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Category" name="category">
                      <Select
                        value={category}
                        onChange={handleCategoryChange}
                        placeholder="Select Category"
                      >
                        <Option value={10}>Add On Per Event</Option>
                        <Option value={11}>Add On Per Pax/Per Hour</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {category === 11 && (
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="Price/event"
                        validateStatus="success"
                        help=""
                        initialValue={0}
                      >
                        <Input
                          addonBefore="IDR"
                          onChange={(e) => {
                            handleInputNumber(
                              e,
                              setInputPricePerEvent,
                              setPricePerEvent
                            );
                          }}
                          value={formatRupiah(pricePerEvent)}
                          id="price_per_event"
                          placeholder="0"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {category === 10 && (
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="Price/pax"
                        validateStatus="success"
                        help=""
                        initialValue={0}
                      >
                        <Input
                          addonBefore="IDR"
                          onChange={(e) => {
                            handleInputNumber(
                              e,
                              setInputPricePerPax,
                              setPricePerPax
                            );
                          }}
                          value={formatRupiah(pricePerPax)}
                          id="price_per_pax"
                          placeholder="0"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {(category === 11 || category === 10) && (
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Pax Type" name="pax_type">
                        <Select
                          value={paxType}
                          onChange={(value) => setPaxType(value)}
                          placeholder="Select Pax Type"
                        >
                          {category === 10 && (
                            <Option value="Per Event">Per Event</Option>
                          )}
                          {category === 11 && (
                            <>
                              <Option value="Per Pax">Per Pax</Option>
                              <Option value="Per Hour">Per Hour</Option>
                            </>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
            <Divider style={{ borderWidth: "1px" }} />
            <div ref={AddOnsImageRef} className="fit-scroll py-2">
              <Row>
                <Col span={8} className="mr-5">
                  <div className="text-xl font-bold text-black mb-2">
                    Upload Photo
                  </div>
                  <div className="text-md font-regular text-gray-500 mb-6">
                    Only 1 (one) photo can be uploaded.
                  </div>
                  <Form.Item
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    name={"attachment"}
                  >
                    <Upload.Dragger
                      listType="picture-card"
                      onPreview={handlePreview}
                      onRemove={handleRemove}
                      beforeUpload={beforeUpload}
                      maxCount={1}
                      fileList={file ? [file] : []}
                      showUploadList={false}
                      accept="image/jpeg,image/png"
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined className="text-primary" />
                      </p>
                      <p className="ant-upload-text font-bold text-primary text-md">
                        Drag and Drop
                        <span className="font-medium text text-gray-600">
                          {" "}
                          photos here
                        </span>
                      </p>
                      <p className="ant-upload-hint">PNG, JPG, Max size. 2MB</p>
                      <p className="mt-3 mb-3 text-gray-400">or</p>
                      <Button type="primary">Upload Photo</Button>
                    </Upload.Dragger>
                  </Form.Item>
                </Col>
                {file && (
                  <>
                    <Divider
                      type="vertical"
                      className="h-80"
                      style={{ borderLeft: "1px solid #ccc" }}
                    />
                    <Col span={8} className="ml-5">
                      <div className="text-xl font-bold text-black mb-2">
                        Uploaded Photo (1)
                      </div>
                      {fileBase64 ? (
                        <Image
                          className="w-60 rounded-lg object-cover cursor-pointer"
                          src={fileBase64}
                          alt="uploaded"
                        />
                      ) : (
                        <div className="text-red-500">
                          Failed to load image preview
                        </div>
                      )}
                      <Modal
                        open={modalPreview}
                        footer={null}
                        onCancel={() => setModalPreview(false)}
                      >
                        {fileBase64 && (
                          <img
                            style={{ width: "100%" }}
                            src={fileBase64}
                            alt="Preview"
                          />
                        )}
                      </Modal>
                    </Col>
                  </>
                )}
              </Row>
              <div className="border-t-2 -mx-5 mt-12">
                <div className="flex justify-end gap-3 px-5 pt-4">
                  <Buttons
                    type={"default"}
                    onClick={() => setSection("default")}
                  >
                    Cancel
                  </Buttons>
                  <Form.Item type="button">
                    <Buttons
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Save
                    </Buttons>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        ) : (
          <OfficeDetail
            {...props}
            AddOnsInformationRef={AddOnsInformationRef}
            AddOnsImageRef={AddOnsImageRef}
          />
        )}
      </div>
    </div>
  );
}
