import React, { useState, useEffect, useRef } from "react";
import { Table, Modal, notification, Tabs, Button } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import _ from "lodash";
import { Buttons, CustomPagination, InputSearch } from ".././../../components/atoms/index";
import {
  PlusOutlined,
  EyeFilled,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import FormSection from "./FormSection/FormSection";
import {
  getListOffice,
  getListOfficeDetail,
  deleteOffice,
  importProductData,
  getOfficeCategory,
  downloadListProduct,
  downloadTemplateImport,
} from "../../../services/api";
import { BiDownload } from "react-icons/bi";
import { downloadFile, numberWithCommas } from "../../../utils/Helper";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import moment from "moment";
import { Link } from "react-router-dom";

const { confirm } = Modal;

export default function OfficeList() {
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  const [childData, setChildData] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const roles = useSelector((state) => state.auth.user.role_slug);
  const [params, setParams] = useState({
    page: 1,
    page_size: 10,
    search: "",
    category: "",
  });
  const [activeTab, setActiveTab] = useState("All Office Type");

  const handleTabChange = (key) => {
    setActiveTab(key);
    setParams({
      ...params,
      category: key === "All" ? "" : key,
      page: 1,
    });
  };

  const getData = () => {
    setLoading(true);
    getListOffice({ ...params })
      .then((res) => {
        if (Array.isArray(res.data.data.result)) {
          setData(res.data.data.result);
        } else {
          setData([res.data.data.result]);
        }
        setTotalItems(res.data.data.total_items);
      })
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCategories = async () => {
    try {
      const categoryData = await getOfficeCategory();
      const formattedCategories = categoryData.map((category) => ({
        key: category.categoryName,
        label: category.categoryName,
      }));
      setCategories([{ key: "All", label: "All Office Types" }, ...formattedCategories]);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
      notification.error({
        message: "Error",
        description: "Failed to fetch office categories",
        placement: "topRight",
      });
    }
  };

  console.log(data);
  console.log(params);
  console.log(totalItems);
  console.log(categories);

  useEffect(() => {
    getData();
    fetchCategories();
  }, [trigger, params]);

  function onPageChange(page, pageSize) {
    setParams({ ...params, page, page_size: pageSize });
  }

  const onSearch = _.debounce(function (e) {
    setData([]);
    setParams({ ...params, search: e.target.value, page: 1 });
  }, 500);

  useEffect(() => {
    if (section === "default") {
      setChildData({});
      getData();
    }
  }, [section]);

  const columns = [
    // {
    //   title: "Office Number",
    // },
    {
      dataIndex: "name",
      title: "Office Name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend", "ascend"],
      sortIcon: ({ sortOrder }) =>
        sortOrder === "ascend" ? (
          <ArrowUpOutlined />
        ) : sortOrder === "descend" ? (
          <ArrowDownOutlined />
        ) : (
          <>
            <ArrowUpOutlined />
            <ArrowDownOutlined />
          </>
        ),
      render: (text) => <span className="font-semibold">{text}</span>,
    },
    {
      title: "Office Category",
      dataIndex: "office_category_name",
      sorter: (a, b) => a.office_category_name - b.office_category_name,
      sortDirections: ["ascend", "descend", "ascend"],
      sortIcon: ({ sortOrder }) =>
        sortOrder === "ascend" ? (
          <ArrowUpOutlined />
        ) : sortOrder === "descend" ? (
          <ArrowDownOutlined />
        ) : (
          <>
            <ArrowUpOutlined />
            <ArrowDownOutlined />
          </>
        ),
    },
    {
      title: "Region",
      dataIndex: "region_name",
      sorter: (a, b) => a.region_name - b.region_name,
      sortDirections: ["ascend", "descend", "ascend"],
      sortIcon: ({ sortOrder }) =>
        sortOrder === "ascend" ? (
          <ArrowUpOutlined />
        ) : sortOrder === "descend" ? (
          <ArrowDownOutlined />
        ) : (
          <>
            <ArrowUpOutlined />
            <ArrowDownOutlined />
          </>
        ),
    },
    {
      title: "Price",
      render: (text, record) => {
        return <span>Rp {numberWithCommas(record.price?.price_daily)}</span>;
      },
      sorter: (a, b) => a.price.price_daily - b.price.price_daily,
      sortDirections: ["ascend", "descend", "ascend"],
      sortIcon: ({ sortOrder }) =>
        sortOrder === "ascend" ? (
          <ArrowUpOutlined />
        ) : sortOrder === "descend" ? (
          <ArrowDownOutlined />
        ) : (
          <>
            <ArrowUpOutlined />
            <ArrowDownOutlined />
          </>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <>
          <div className="flex items-center gap-x-1">
            <Buttons
              className="py-1 rounded-lg hover:border-primary !border-0"
              icon={<EyeFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => viewData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary !border-0"
              icon={<EditFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => editData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary !border-0"
              icon={<DeleteFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => deleteData(record)}
            />
          </div>
        </>
      ),
    },
  ];

  const editData = (record) => {
    getListOfficeDetail(record.id)
      .then((res) => {
        const data = res.data.data;
        data.price_overtime = data.price_per_hour_overtime;
        console.log("editData", data);
        setChildData(data);
        setSection("edit");
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const viewData = (record) => {
    getListOfficeDetail(record.id)
      .then((res) => {
        console.log(res.data.data);
        const data = res.data.data;
        setChildData(data);
        setSection("view");
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const addData = () => {
    setSection("add");
  };

  const deleteData = (record) => {
    confirm({
      title: "Delete Office?",
      content: "Are you sure you want to delete this data? This action cannot be undone.",
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteOffice(record.id)
            .then((res) => {
              notification.success({
                message: "Success!",
                description: "Successfully delete data",
                placement: "topRight",
              });
              getData();
              resolve(res);
            })
            .catch((err) => {
              notification.error({
                message: "Failed!",
                description: err?.data?.stat_msg ? err?.data?.stat_msg : "Failed to delete data",
                placement: "topRight",
              });
              reject(err);
            });
        });
      },
    });
  };

  const hiddenFileInput = useRef(null);

  // const handleClick = () => {
  //   hiddenFileInput.current.click();
  // };

  const handleChange = async (event) => {
    setLoadingImport(true);
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append("file", fileUploaded);
    await importProductData(formData)
      .then((res) => {
        if (res) {
          notification.success({
            message: "Success!",
            description: "Successfully import office data",
            placement: "topRight",
          });
          getData();
        }
      })
      .catch((err) => {
        notification.error({
          message: "Failed!",
          description: "Failed to import office data",
          placement: "topRight",
        });
        throw new Error(err);
      })
      .finally(() => {
        setLoadingImport(false);
      });
  };

  const handleDownloadData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/office/report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await response.blob();
      saveAs(blob, `REPORT_${moment().format("l")}.xlsx`);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  // const handleDownloadData = () => {
  //   try {
  //     setLoadingDownload(true);
  //     const response = downloadListProduct();
  //     const blob = response.blob();
  //     saveAs(blob, `REPORT_${moment().format("l")}.xlsx`);
  //     setLoadingDownload(false);
  //   } catch (error) {
  //     console.log("Error");
  //     setLoadingDownload(false);
  //   }
  // };
  // const handleDownloadTemplate = () => {
  //   downloadTemplateImport()
  //     .then((res) => {
  //       downloadFile(res.data.data);
  //     })
  //     .catch((err) => {
  //       return new Error(err);
  //     });
  // };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="breadcrumb mb-4 text-md">
            <Link to="/" className="text-gray-500">
              Dashboard
            </Link>
            <span className="mx-2">&gt;</span>
            <span>Office List</span>
          </div>
          <div className="header">
            <h2 className="lg:text-[28px] text-[20px]">Office List</h2>
            <div className="flex flex-col md:flex-row items-center md:gap-4 gap-1">
              <button
                className={`flex items-center justify-center px-4 py-2 rounded-md bg-[#F9E7E7] text-primary hover:bg-[#F0D0D0] transition-colors ${loadingDownload ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={handleDownloadData}
                disabled={loadingDownload}
              >
                {loadingDownload ? (
                  <span className="mr-2 animate-spin">&#9696;</span>
                ) : (
                  <BiDownload className="mr-2" />
                )}
                Download Data
              </button>
              {roles !== "super-admin-distributor" && (
                <Buttons type="primary" icon={<PlusOutlined />} onClick={() => addData()}>
                  Add Office
                </Buttons>
              )}
            </div>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </div>
          <div className="mb-2 md:w-80 w-32">
            <InputSearch onChange={onSearch} placeholder="Search Office" />
          </div>
          <Tabs activeKey={activeTab} onChange={handleTabChange} items={categories} />
          <div className="body ">
            <div className="w-full overflow-x-auto">
              <Table
                dataSource={data}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{
                  current: params.page,
                  pageSize: params.page_size,
                  total: totalItems,
                  onChange: onPageChange,
                  showTotal: (total, range) => (
                    <div>
                      Showing <strong>{range[1]}</strong> of <strong>{total}</strong> entries
                    </div>
                  ),
                  className: "ant-table-pagination-custom",
                  itemRender: (_, type, originalElement) => {
                    if (type === "prev") {
                      return <Button type="text">&lt;</Button>;
                    }
                    if (type === "next") {
                      return <Button type="text">&gt;</Button>;
                    }
                    return originalElement;
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
      {section !== "default" && (
        <FormSection
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )}
    </>
  );
}
