import { Col, Modal, Row, Upload } from "antd";
import React, { useRef, useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import {
  addOfficeCatalogue,
  deleteOfficeCatalogue,
  updateOfficeCatalogue,
} from "../../services/api";

const { Dragger } = Upload;

const OfficeImageUpload = ({ fileList, setFileList, section, officeId }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const fileInputRef = useRef(null);

  const handlePreview = (file) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleAdd = async (file) => {
    try {
      if (section === "edit" && officeId) {
        const formData = new FormData();
        formData.append("office_catalogue[]", file);

        const response = await addOfficeCatalogue(officeId, formData);

        if (response.status && response.data) {
          const newImage = {
            id: response.data.id,
            office_id: response.data.office_id,
            url: response.data.url,
            uid: response.data.id.toString(),
            name: file.name,
            status: "done",
          };

          setFileList((prevFileList) => [...prevFileList, newImage]);
        } else {
          throw new Error("Unexpected response format");
        }
      } else {
        // For new office creation
        const reader = new FileReader();
        reader.onload = () => {
          setFileList((prevFileList) => [
            ...prevFileList,
            {
              uid: Math.random().toString(),
              name: file.name,
              status: "done",
              url: reader.result,
            },
          ]);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("Error adding image:", error);
      Modal.error({
        message: "Error",
        description: "Failed to add the image. Please try again.",
      });
    }
  };

  const handleRemove = async (file) => {
    try {
      if (section === "edit" && file.uid) {
        await deleteOfficeCatalogue(officeId);
      }
      const updatedFileList = fileList.filter((item) => item !== file);
      setFileList(updatedFileList);
    } catch (error) {
      console.error("Error deleting image:", error);
      Modal.error({
        title: "Error",
        content: "Failed to delete the image. Please try again.",
      });
    }
  };

  const handleEdit = async (file) => {
    try {
      if (section === "edit" && file.uid) {
        const newFile = await new Promise((resolve) => {
          fileInputRef.current.onchange = (e) => resolve(e.target.files[0]);
          fileInputRef.current.click();
        });

        if (newFile) {
          const formData = new FormData();
          formData.append("office_catalogue[]", newFile);

          await updateOfficeCatalogue(officeId, formData);

          const reader = new FileReader();
          reader.onload = (e) => {
            const updatedFileList = fileList.map((item) =>
              item.uid === file.uid ? { ...item, url: e.target.result } : item,
            );
            setFileList(updatedFileList);
            setPreviewImage(e.target.result);
          };
          reader.readAsDataURL(newFile);
        }
      }
    } catch (error) {
      console.error("Error updating image:", error);
      Modal.error({
        title: "Error",
        content: "Failed to update the image. Please try again.",
      });
    }
  };

  const beforeUpload = (file) => {
    handleAdd(file);
    return false;
  };

  const draggerProps = {
    name: "file",
    fileList: fileList,
    multiple: true,
    beforeUpload: beforeUpload,
    listType: "picture-card",
    maxCount: 5,
    showUploadList: false,
  };

  return (
    <Row gutter={16} className="items-start">
      <Col span={12}>
        <div className="text-lg font-bold text-baseColor mb-2">Upload Photo</div>
        <Dragger {...draggerProps} className="w-full !bg-white">
          <p className="ant-upload-drag-icon text-center flex items-center justify-center">
            <MdOutlineFileUpload className="text-primary w-6 h-6 text-center" />
          </p>
          <p className="ant-upload-text">
            <span className="font-bold text-primary">Drag and Drop</span> photos here
          </p>
          <p className="ant-upload-hint">PNG, JPG, Max Size 2MB</p>
        </Dragger>
      </Col>
      <Col span={12}>
        <div className="text-lg font-bold text-baseColor mb-2">
          Uploaded Photos ({fileList.length})
        </div>
        <div className="flex flex-wrap gap-2">
          {fileList.map((file, index) => (
            <div key={index} className="relative group">
              <img
                src={file.url}
                alt={`uploaded-${index}`}
                className={`w-24 h-24 object-cover cursor-pointer rounded-md ${index === 0 ? "border-2 border-[#D36161]" : ""}`}
                onClick={() => handlePreview(file)}
              />
              {index === 0 && (
                <div className="absolute top-0 left-0 bg-blue-500 text-white text-[10px] px-1 py-0.5 bg-[#D36161] rounded-e-md rounded-tl-md">
                  Main Photo
                </div>
              )}
            </div>
          ))}
        </div>
      </Col>
      <Modal
        open={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        title="Image Preview"
      >
        <img alt="preview" style={{ width: "100%" }} src={previewImage} />
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={() => {
              const file = fileList.find((file) => file.url === previewImage);
              handleEdit(file);
            }}
            className="bg-white text-black px-4 py-2 rounded hover:bg-blue-600 transition-colors"
          >
            Edit Image
          </button>
          <button
            onClick={() => {
              Modal.confirm({
                title: "Are you sure you want to delete this image?",
                content: "This action cannot be undone.",
                okText: "Yes",
                cancelText: "No",
                onOk: () => {
                  handleRemove(fileList.find((file) => file.url === previewImage));
                  setPreviewVisible(false);
                },
              });
            }}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors"
          >
            Delete Image
          </button>
        </div>
      </Modal>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          if (e.target.files[0]) {
            handleEdit(fileList.find((file) => file.url === previewImage));
          }
        }}
      />
    </Row>
  );
};

export default OfficeImageUpload;
