import { HomeFilled, UserOutlined } from "@ant-design/icons";
import {
  BuildingFill,
  Calendar2CheckFill,
  ImageFill,
  PatchPlusFill,
  PersonFill
} from "react-bootstrap-icons";

function getItem(label, key, icon, children) {
  return {
    label,
    key,
    icon,
    children,
  };
}

export const items = [
  getItem("Dashboard", "/", <HomeFilled />),
  getItem("Customers", "/customers", <UserOutlined />, [
    getItem("List Customers", "/customers/list"),
  ]),
  getItem("Office", "/office", <BuildingFill />, [
    getItem("Office List", "/office/list"),
    // getItem("Serviced Office", "/office/serviced-office"),
    // getItem("Meeting Room", "/office/meeting-room"),
    // getItem("Board Room", "/office/board-room"),
    // getItem("Class Room", "/office/class-room"),
    // getItem("Coworking Space (Dedicated)", "/office/coworking-space-d"),
    // getItem("Coworking Space (Non-Dedicated)", "/office/coworking-space-nd"),
    // getItem("Seminar Room", "/office/seminar-room"),
    // getItem("Theathre Room", "/office/theathre-room"),
    // getItem("Combined Boardroom Meeting Room", "/office/comb-board-meeting"),
  ]),
  getItem("Add-Ons", "/addons", <PatchPlusFill />, [
    getItem("List Add-Ons", "/addons/list"),
  ]),
  getItem("Booking", "/booking", <Calendar2CheckFill />, [
    getItem("List Booking", "/booking/list"),
  ]),
  // getItem("Promo", "/promo", <BiSolidDiscount />, [
  //   getItem("Manage Discount", "/promo/discount"),
  //   // getItem("Manage Flashsale", "/promo/flashsale"),
  //   // getItem("Manage Cashback", "/promo/cashback"),
  // ]),
  getItem("Admin", "/admin", <PersonFill />, [
    getItem("List Admin", "/admin/list"),
  ]),
  // getItem("Room Management", "/room", <PersonWorkspace />, [
  //   getItem("List Room", "/room/list"),
  // ]),
  getItem("Information", "/information", <ImageFill />, [
    getItem("List Information", "/information/list"),
    // getItem("List Banner", "/banner/list"),
    // getItem("List Article", "/banner/article"),
  ]),
  // getItem("CMS", "/cms", <InfoCircleFill />, [
  //   getItem("FAQ", "/cms/FAQ"),
  //   getItem("Contact", "/cms/contact"),
  //   // getItem("Our Partners", "/info/partner"),
  //   // getItem("Terms & Condition", "/info/tc"),
  // ]),
  // getItem("Manage Help", "/support", <GearFill />, [
  //   getItem("Complain", "/support/complain"),
  // ]),
];
