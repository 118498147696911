import {
  BellFilled,
  DownOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Divider,
  Dropdown,
  Layout,
  Menu,
  Modal,
  notification,
  Popover,
} from "antd";
import Sider from "antd/es/layout/Sider";
import React, { useEffect, useState } from "react";
import { BoxArrowRight, LockFill } from "react-bootstrap-icons";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarContent from "../../components/organism/Sidebar";
import { resetUserData } from "../../redux/auth/action";

const { Header, Content } = Layout;

export default function TopNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [notifData, setNotifData] = useState([]);
  const [notifParams, setNotifParams] = useState({
    offset: 0,
    order: "id,desc",
    limit: 10,
  });

  const [clickedSidebar, setClickedSidebar] = useState(false);

  function handleClickSidebar() {
    setClickedSidebar(!clickedSidebar);
  }

  useEffect(() => {
    if (!location.pathname.includes("admin")) {
      //   getNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  //   function getNotification() {
  //     getNotificationUser(notifParams)
  //       .then((res) => {
  //         if (res) {
  //           setNotifData(res.data.data);
  //         }
  //       })
  //       .catch((err) => {
  //         throw new Error(err);
  //       });
  //   }

  const dropdownMenu = (
    <Menu className="divide-y-2">
      <Menu.Item key="0">
        <div
          className="flex items-center"
          onClick={() => navigate("/admin/change-password")}
        >
          <div className="flex w-6 text-gray-600">
            <LockFill className="text-gray-600" />
          </div>
          Change Password
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        <div
          onClick={() => confirmLogout()}
          className="flex items-center text-danger"
        >
          <div className="flex w-6">
            <BoxArrowRight className="text-danger" />
          </div>
          Logout
        </div>
      </Menu.Item>
    </Menu>
  );

  const items = [
    {
      label: "Change Password",
      key: "0",
      onClick: () => navigate("/admin/change-password"),
      icon: <LockFill className="text-gray-600" />,
    },
    {
      label: "Logout",
      key: "1",
      onClick: () => confirmLogout(),
      icon: <BoxArrowRight className="text-danger" />,
    },
  ];

  const Logout = () => {
    dispatch(resetUserData());
    navigate("/admin/login");
    notification.success({
      message: "Logout",
      description: "You have successfully logged out",
    });
  };

  const confirmLogout = () => {
    Modal.confirm({
      title: "Logout",
      content: "Are you sure you want to Logout?",
      okText: "Yes, Logout",
      cancelText: "Cancel",
      onOk: () => Logout(),
    });
  };

  const content = () => (
    <div className="w-[400px]">
      <div className="font-medium p-3 text-md shadow-sm w-full">
        Notification
      </div>
      <div className="overflow-x-auto max-h-[440px]">
        {notifData?.length > 0 ? (
          <div>
            {notifData.map((item, index) => (
              <div
                className={
                  `${item.is_read ? "bg-secondary" : "bg-primary-50"}` +
                  " px-3 py-2 pb-0 cursor-pointer hover:bg-primary-50/50"
                }
                key={index}
              >
                <div className="flex items-center gap-2 font-medium">
                  {" "}
                  <InfoCircleOutlined style={{ color: "#08c" }} /> {item.title}
                </div>
                <div>{item.notification_message}</div>
                <div className="border-b py-1"></div>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-24 flex items-center justify-center text-secondary">
            No notification yet
          </div>
        )}
      </div>
      <div className="flex px-3 py-2 border-t justify-between">
        <div
          className={`${notifData?.length > 0
            ? "text-primary cursor-pointer hover:underline"
            : "text-secondary"
            }`}
        >
          Mark All Reads
        </div>
        <div className="text-primary cursor-pointer hover:underline">
          View More
        </div>
      </div>
    </div>
  );

  return (
    <Layout>
      <Header className="bg-white flex justify-between items-center border-b border-grey-900 mb-2">
        <div className="cursor-pointer">
          <span className="text-xl md:text-3xl font-black text-primary">
            CEO SUITE
          </span>
        </div>
        <div className="flex items-center ">
          <Popover content={content} trigger="click">
            <div className="cursor-pointer mr-4">
              <div className="absolute ml-2 -mt-1">
                <Badge
                  size="small"
                  count={notifData?.length}
                  className="cursor-pointer"
                />
              </div>
              <BellFilled
                style={{ fontSize: "18px", color: "#6B7280" }}
                className=" relative left-5"
              />
            </div>
          </Popover>
          <Divider
            type="vertical"
            style={{ height: "2em" }}
            className="mx-4 border-gray-300"
          />
          <div className="flex items-center">
            <UserOutlined
              style={{ fontSize: "16px", color: "gray" }}
              className="border rounded-full border-1 p-1 hidden md:block"
            />

            <div className="ml-3 flex justify-center items-center">
              <div className="leading-none text-md font-semibold text-gray-600 md:text-[16px] text-[14px] flex flex-row gap-2 ">
                Hi, {user.name}
                <Dropdown menu={{ items }} placement="bottomRight">
                  <DownOutlined
                    className=""
                    onClick={(e) => e.preventDefault()}
                    style={{
                      fontSize: "16px",
                      color: "#6B7280",
                      fontWeight: "bold",
                    }}
                  />
                </Dropdown>
              </div>
              <div className="leading-none mt-1 text-sm text-gray-700">
                {user.role}
              </div>
            </div>
          </div>
          <Divider
            type="vertical"
            style={{ height: "2em" }}
            className="mx-4 border-gray-300 bg-warning"
          />
          <div
            className="lg:hidden flex items-center"
            onClick={handleClickSidebar}
          >
            <GiHamburgerMenu />
          </div>
        </div>
      </Header>
      <Layout style={{ paddingLeft: clickedSidebar ? 250 : 0 }}>
        {clickedSidebar && (
          <Sider
            theme="light"
            style={{
              overflowY: "scroll",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 60,
              bottom: 0,
              zIndex: 1000,
              backgroundColor: "#fff",
              transition: "0.3s", // Optional: for smooth transition
            }}
            width={250}
          >
            <SidebarContent />
          </Sider>
        )}
        <Content
          style={{ marginLeft: clickedSidebar ? 250 : 0, transition: "0.3s" }}
        >
          {/* Main content goes here */}
        </Content>
      </Layout>
    </Layout>
  );
}
