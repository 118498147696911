import { Table } from "antd";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { BiDownload } from "react-icons/bi";
import {
  Buttons
} from "../../components/atoms/index";
import {
  getCustomerDetail,
  getListCustomers
} from "../../services/api";

export default function Classification() {
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  const [childData, setChildData] = useState({});
  const [totalData, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    order: "id",
    keyword: "",
  });

  useEffect(() => {
    getData();
  }, [params]);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getListCustomers(params);
      // Safely access nested data with optional chaining
      const customerData = response?.data?.data?.result;

      if (Array.isArray(customerData)) {
        setData(customerData);
        setTotal(customerData.length);
      } else if (customerData) {
        // Handle single object case
        setData([customerData]);
        setTotal(1);
      } else {
        // Handle empty or invalid response
        setData([]);
        setTotal(0);
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      setData([]);
      setTotal(0);
    } finally {
      setLoading(false);
    }
  };

  function onPageChange(page, pageSize) {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    setParams({ ...params, offset, limit });
  }

  const handleSearch = (event) => {
    const { value } = event.target;
    setParams(prev => ({ ...prev, keyword: value }));
  };

  useEffect(() => {
    if (section === "default") {
      setChildData({});
    }
    return () => { };
  }, [section]);

  const columns = [
    {
      title: "Full Name",
      dataIndex: "full_name",
      sorter: (a, b) => (a?.full_name ?? '').localeCompare(b?.full_name ?? '')
    },
    {
      title: "Position",
      dataIndex: "position",
      sorter: (a, b) => (a?.position ?? '').localeCompare(b?.position ?? '')
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      sorter: (a, b) => (a?.company_name ?? '').localeCompare(b?.company_name ?? '')
    },
    {
      title: "Company Address",
      dataIndex: "company_address"
    },
    {
      title: "Company Email",
      dataIndex: "company_email"
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phone_number"],
      render: (text, record) => record?.user?.phone_number ?? 'N/A'
    }
  ];

  const viewData = (record) => {
    // console.log(record);
    getCustomerDetail(record.id)
      .then((res) => {
        setChildData(res.data.data);
        setSection("view");
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // const viewData = (record) => {
  //   getCustomerDetail(record.uuid).then((res) => {
  //     setChildData(res.data.data);
  //     setSection("view");
  //   });

  const [loadingDownload, setLoadingDownload] = useState(false);

  // const handleDownloadData = () => {
  //   setLoadingDownload(true);
  //   downloadListCustomer(params)
  //     .then((res) => {
  //       downloadFile(res.data.data);
  //     })
  //     .catch((err) => {
  //       return new Error(err);
  //     })
  //     .finally(() => {
  //       setLoadingDownload(false);
  //     });
  // };

  const handleDownload = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/report?user_type=customer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Download failed');
      }

      const blob = await response.blob();
      const fileName = `CUSTOMER_REPORT_${new Date().toLocaleDateString()}.xlsx`;
      saveAs(blob, fileName);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="header">
            <h2>Customers List</h2>
            <Buttons
              type="success"
              icon={<BiDownload />}
              loading={loadingDownload}
              onClick={handleDownload}
            >
              Download Data
            </Buttons>
          </div>
          <div className="body">
            <div className="flex justify-between items-end mb-2">
              {/* <div className="flex gap-4 mb-2">
                <div>
                  <div className="mb-2">Company Name</div>
                  <InputSearch
                    onChange={onSearch}
                    placeholder="Search Company Name"
                  />
                </div>
              </div> */}
              {/* <div className="mb-2">
                <Buttons
                  type="success"
                  icon={<BiDownload />}
                  loading={loadingDownload}
                  onClick={handleDownloadData}
                >
                  Download Data
                </Buttons>
              </div> */}
            </div>
            <div className="w-full overflow-x-auto">
              <Table
                dataSource={data}
                columns={columns}
                rowKey="id"
                loading={loading}
              />
            </div>
          </div>
        </div>
      )}
      {/* {section !== "default" && (
        <CustomerListForm
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )} */}
    </>
  );
}
